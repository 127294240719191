<!--
 * @Author: 智客云网络科技
 * @Date: 2021-05-20 11:23:11
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:10:46
 * @Description: 创建 修改 渠道计费模式
 * @FilePath: \src\views\App\Channel\NUBillingMode.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="10vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <el-form :model="m" :inline="true" ref="m" label-width="110px">
          <el-form-item label="模式名称：" prop="name" style="width: 45%">
            <el-input
              size="small"
              v-model="m.name"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="模式简称："
            prop="abbrev_name"
            style="width: 45%"
          >
            <el-input
              size="small"
              v-model="m.abbrev_name"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="默认：" style="width: 30%">
            <el-switch
              size="small"
              v-model="m.default"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <el-form-item label="状态：" style="width: 30%">
            <el-switch
              size="small"
              v-model="m.status"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <div class="from-notes-box">
            <el-form-item
              label="计费公式："
              prop="notes"
              style="width: 90%; display: block"
            >
              <el-input
                size="small"
                v-model="m.formula"
                type="textarea"
                readonly
                :rows="5"
                @click.native="_OpenFormulaEdit"
              ></el-input>
            </el-form-item>
          </div>

          <div class="from-notes-box">
            <el-form-item
              label="描述："
              prop="notes"
              style="width: 90%; display: block"
            >
              <el-input
                size="small"
                v-model="m.notes"
                type="textarea"
                :rows="5"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </el-card>

      <el-divider></el-divider>

      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="_CreateNewChannelBillingMode()"
        v-if="id == 0"
        >提交</el-button
      >
      <el-button
        size="small"
        type="primary"
        icon="el-icon-check"
        @click="_UpdateChannelBillingMode()"
        v-else
        >保存</el-button
      >
      <el-button size="small" icon="el-icon-close" @click="isShow = false"
        >取消</el-button
      >
    </div>

    <!-- 公式编辑器 -->
    <formula-edit
      v-model="m.formula"
      :show.sync="show_formula_edit"
    ></formula-edit>
  </el-dialog>
</template>

<script>
import {
  createChannelBillingMode,
  updateChannelBillingMode,
  getChannelBillingModeInfo,
} from "./api"; //页面专有接口
import formulaEdit from "@/components/formulaEdit"; //公式编辑器组件
export default {
  components: { formulaEdit },
  data() {
    return {
      m: {
        name: "", //渠道名称
        abbrev_name: "", //渠道简称
        default: 0, //默认
        status: 1, //渠道状态
        formula: "", //计费公式
        notes: "", //描述
      },
      id: 0, //
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制
      kch_id: null, //渠道ID
      show_formula_edit: false, //显示公式编辑器
    };
  },
  methods: {
    // 打开
    open(id, kch_id = null) {
      this.isShow = true; //显示组件
      this.id = id; //重写ID
      this.kch_id = kch_id; //渠道ID
      this.$nextTick(() => {
        if (id === 0) {
          //新建
          this.title = "添加新计费模式";
          this.loading = false;
        } else {
          //修改
          this._GetChannelBillingModeInfo(id);
          this.title = "修改计费模式信息";
        }
      });
    },

    //打开公式编辑组件
    _OpenFormulaEdit() {
      this.show_formula_edit = true;
    },

    //添加渠道计费模式
    _CreateNewChannelBillingMode() {
      var m = this.m;
      createChannelBillingMode({
        kch_id: this.kch_id,
        name: m.name,
        abbrev_name: m.abbrev_name,
        default: m.default,
        status: m.status,
        formula: m.formula,
        notes: m.notes,
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改渠道计费模式
    _UpdateChannelBillingMode() {
      var m = this.m;
      updateChannelBillingMode({
        kchp_id: this.id,
        name: m.name,
        abbrev_name: m.abbrev_name,
        default: m.default,
        status: m.status,
        formula: m.formula,
        notes: m.notes,
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取渠道计费模式信息
    _GetChannelBillingModeInfo(kchp_id) {
      getChannelBillingModeInfo({ kchp_id })
        .then((res) => {
          let m = this.m;
          let d = res.data;
          m.name = d.name;
          m.abbrev_name = d.abbrev_name;
          (m.default = d.default), (m.status = d.status);
          m.formula = d.formula;
          m.notes = d.notes;
          this.loading = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent.$parent._Load(); //通知父组件刷新数据
    },
  },
  created() {},
};
</script>

<style>
.address-box .el-form-item__content {
  width: calc(100% - 100px);
}

.formula-name-box {
  line-height: 16px;
}

.copy-formula-name {
  cursor: pointer;
  color: rgb(0, 153, 255);
  font-size: 16px;
}
</style>
