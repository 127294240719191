<!--
 * @Author: 智客云网络科技
 * @Date: 2021-12-14 17:02:24
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:10:37
 * @Description: 公式编辑器
 * @FilePath: \src\components\formulaEdit\index.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible="show"
    :value="value"
    @input="_SetValue"
    width="80%"
    height="100%"
    top="1vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="true"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-row :gutter="20">
        <el-col :span="13">
          <el-card shadow="never">
            <el-form :inline="true" ref="m" label-width="110px">
              <div class="from-notes-box">
                <el-form-item
                  label="计费公式："
                  prop="notes"
                  style="width: 100%; display: block"
                >
                  <el-input
                    size="small"
                    v-model="formula"
                    type="textarea"
                    :rows="26"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </el-card>
        </el-col>

        <el-col :span="11">
          <el-card shadow="never">
            <el-descriptions class="margin-top" :column="1" size="mini" border>
              <el-descriptions-item
                v-for="(item, i) in formulaExplain"
                :key="i"
              >
                <template slot="label" class="formula-name-box">
                  <i
                    class="el-icon-paperclip copy-formula-name"
                    @click="$utils.copyText(item.name)"
                  ></i>
                  &nbsp;{{ item.name }}
                </template>
                {{ item.tips }}
              </el-descriptions-item>
            </el-descriptions>
          </el-card>
        </el-col>

        <el-col :span="24">
          <el-divider></el-divider>

          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="_Ok"
            >确认</el-button
          >
          <el-button size="small" icon="el-icon-close" @click="_HandleClose">
            取消
          </el-button>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "formulaEdit",
  props: {
    value: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "公式编辑器", //弹窗标题
      formula: "", //公式
      formulaExplain: [
        {
          name: "$weight",
          tips: "包裹重量。",
        },

        {
          name: "$trilateral_length",
          tips: "包裹三边长度。",
        },

        {
          name: "$reinforced_packing",
          tips: "是否加固打包(可选值[1:加固打包], [0:非加固打包])。",
        },

        {
          name: "$cc_type",
          tips: "清关类型(可选值[1:个人目录清关], [2:企业目录通关], [3:个人简易通关], [4:企业简易通关])。",
        },

        {
          name: "$id_code",
          tips: "个人清关号码。",
        },

        {
          name: "$material_cost",
          tips: "包装材料费。",
        },

        {
          name: "$additional_cost",
          tips: "附加费费。",
        },

        {
          name: "if(逻辑比较,满足时返回的值,不满足时返回的值)",
          tips: "按条件进行逻辑比较, 满足时返回一个值, 不满足时返回另一个值。",
        },

        {
          name: "find(搜索字符串, 要搜索的字符串)",
          tips: "用于在第二个文本字符串中定位第一个文本串。并返回第一个文本串的起始位置",
        },

        {
          name: "ceil(运算值)",
          tips: "将传入的值进行向上取整后返回。",
        },

        {
          name: "round(运算值)",
          tips: "将传入的值进行四舍五入后返回。",
        },

        {
          name: "floor(运算值)",
          tips: "将传入的值进行向下取整后返回。",
        },

        {
          name: "bcadd(运算值1, 运算值2)",
          tips: "将两个高精度数字相加。",
        },

        {
          name: "bcsub(运算值1, 运算值2)",
          tips: "将两个高精度数字相减。",
        },

        {
          name: "bcmul(运算值1, 运算值2)",
          tips: "将两个高精度数字相乘。",
        },

        {
          name: "bcdiv(运算值1, 运算值2)",
          tips: "将两个高精度数字相除。",
        },

        {
          name: "bcmod(运算值1, 运算值2)",
          tips: "求高精度数字余数。",
        },

        {
          name: "bcpow(运算值1, 运算值2)",
          tips: "求高精度数字乘方。",
        },

        {
          name: "bcpowmod(运算值1, 运算值2)",
          tips: "求高精度数字乘方求模。",
        },

        {
          name: "bcsqrt(运算值1, 运算值2)",
          tips: "求高精度数字平方根。",
        },
      ], //公式解释
    };
  },
  watch: {
    show() {
      if (this.show) {
        this._SetDefault();
      }
    },
  },
  methods: {
    //完成公式编辑
    _Ok() {
      this._SetValue(this.formula);
      this.$nextTick(() => {
        this._HandleClose();
      });
    },

    //设置公式
    _SetValue(val) {
      this.$emit("input", val);
    },

    _SetDefault() {
      if (this.value !== "") {
        this.formula = this.value;
      }
    },

    //关闭
    _HandleClose() {
      this.$emit("update:show", false); //触发input事件, 并传入新值
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>
