<!--
 * @Author: 智客云网络科技
 * @Date: 2021-05-20 11:23:11
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:09:59
 * @Description: 计费模式管理
 * @FilePath: \src\views\App\Channel\BillingModeManage.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="80%"
    top="5vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <!-- ------------- 快捷按钮 ------------- -->
        <div class="table-btn">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="_OpenNewChannelBillingModeModel()"
            >添加计费模式</el-button
          >
          <el-button
            size="small"
            type="info"
            icon="el-icon-refresh"
            @click="_Load()"
            >刷新</el-button
          >
        </div>

        <!-- ------------- 数据列表 ------------- -->
        <el-table :data="TableData" size="mini" stripe border row-key="id">
          <!-- ID -->
          <el-table-column
            label="ID"
            prop="Aid"
            align="center"
            width="50px"
          ></el-table-column>

          <!-- 模式名称 -->
          <el-table-column
            label="模式名称"
            prop="name"
            align="center"
            width="220px"
          ></el-table-column>

          <!-- 模式简称 -->
          <el-table-column
            label="模式简称"
            prop="abbrev_name"
            align="center"
            width="220px"
          ></el-table-column>

          <!-- 默认 -->
          <el-table-column label="默认" align="center" width="80px">
            <template slot-scope="s">
              <el-tag
                size="small"
                effect="plain"
                type="success"
                v-if="s.row.default == 1"
                >默认</el-tag
              >
              <el-tag size="small" effect="plain" type="info" v-else
                >非默认</el-tag
              >
            </template>
          </el-table-column>

          <!-- 当前状态 -->
          <el-table-column label="当前状态" align="center" width="80px">
            <template slot-scope="s">
              <el-tag
                size="small"
                effect="plain"
                type="success"
                v-if="s.row.status == 1"
                >启用</el-tag
              >
              <el-tag size="small" effect="plain" type="danger" v-else
                >禁用</el-tag
              >
            </template>
          </el-table-column>

          <!-- 描述 -->
          <el-table-column label="描述">
            <template slot-scope="s"
              ><span>{{ s.row.notes }}</span></template
            >
          </el-table-column>

          <!-- 添加时间 -->
          <el-table-column
            label="创建时间"
            prop="add_time"
            align="center"
            width="140px"
          ></el-table-column>

          <!-- 操作 -->
          <el-table-column label="操作" align="center" width="200px">
            <template slot-scope="s">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click="_OpenUpdateChannelBillingModeModel(s.row.kchp_id)"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="_DelChannelBillingMode(s.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <!-- ------------- 分页 ------------- -->
        <div class="page-box">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            :current-page.sync="p.pageNo"
            :page-size.sync="p.pageSize"
            :total="p.dataCount"
            :page-sizes="[10, 50, 100, 150]"
            @current-change="_Load()"
            @size-change="_Load()"
          >
          </el-pagination>
        </div>
      </el-card>

      <!-- 增改组件 -->
      <nu-billing-mode ref="nu-billing-mode"></nu-billing-mode>
    </div>
  </el-dialog>
</template>

<script>
import NuBillingMode from "./NUBillingMode";
import { getChannelBillingModeTable, delChannelBillingMode } from "./api"; //页面专有接口
export default {
  components: {
    NuBillingMode,
  },
  data() {
    return {
      p: {
        kor_id: "", //组织ID
        pageNo: 1, //当前页
        pageSize: 10, //分页行数
        dataCount: 0, //数据总行数
      },
      TableData: [], // 数据集合

      id: 0, //
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制
    };
  },
  watch: {
    //监听组织ID变动刷新表格
    "p.kor_id": {
      handler() {
        this._Load();
      },
    },
  },
  methods: {
    // 打开
    open(id) {
      this.isShow = true; //显示组件
      this.id = id; //重写ID
      this.title = "渠道计费模式管理";
      this._Load(); //加载表格数据
      this.$nextTick(() => {
        this.loading = false;
      });
    },

    //打开渠道计费模式创建组件
    _OpenNewChannelBillingModeModel() {
      this.$refs["nu-billing-mode"].open(0, this.id);
    },

    //打开渠道计费模式修改组件
    _OpenUpdateChannelBillingModeModel(id) {
      this.$refs["nu-billing-mode"].open(id);
    },

    _DelChannelBillingMode(data) {
      var _vm = this;
      this.$utils.confirm(
        "确认删除渠道【" +
          data.name +
          '】吗？</br><b style="color:red">此操作将立即生效并且不可撤销!</b>',
        () => {
          delChannelBillingMode({ kchp_id: data.kchp_id })
            .then((res) => {
              _vm.$utils.success(res.err_msg);
              _vm._Load(); //重载表格数据
            })
            .catch((err) => {
              _vm.$utils.error(err.err_msg);
            });
        }
      );
    },

    //加载表格数据
    _Load() {
      getChannelBillingModeTable({
        pageNo: this.p.pageNo,
        pageSize: this.p.pageSize,
        kch_id: this.id,
      })
        .then((res) => {
          this.TableData = res.data.data;
          this.p.dataCount = res.data.dataCount;
        })
        .catch((err) => {
          this.TableData = [];
          if (err.code === 160004) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },
  },
  created() {},
};
</script>

<style>
.from-notes-box .el-form-item__content {
  width: 84%;
}
.address-box .el-form-item__content {
  width: calc(100% - 100px);
}
</style>
