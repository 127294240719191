/*
 * @Author: 智客云网络科技
 * @Date: 2021-11-01 15:00:52
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:00:48
 * @Description: 页面专属接口管理
 * @FilePath: \src\views\App\Channel\api.js
 */

import http from "@/api";

/**************************** 渠道操作 ****************************/

// 添加新渠道
export const createChannel = (params) =>
  http.post("/v1/Channel/CreateChannel", params);

// 删除渠道
export const delChannel = (params) =>
  http.delete("/v1/Channel/DelChannel", params);

// 修改渠道
export const updateChannel = (params) =>
  http.put("/v1/Channel/UpdateChannel", params);

// 根据ID查询渠道信息
export const getChannelInfo = (params) =>
  http.get("/v1/Channel/GetChannelInfo", params);

// 获取渠道表格
export const getChannelTable = (params) =>
  http.get("/v1/Channel/GetChannelTable", params);

/**************************** 渠道计费模式操作 ****************************/
// 添加新渠道计费模式
export const createChannelBillingMode = (params) =>
  http.post("/v1/Channel/CreateChannelBillingMode", params);

// 删除渠道计费模式
export const delChannelBillingMode = (params) =>
  http.delete("/v1/Channel/DelChannelBillingMode", params);

// 修改渠道计费模式
export const updateChannelBillingMode = (params) =>
  http.put("/v1/Channel/UpdateChannelBillingMode", params);

// 根据ID查询渠道计费模式信息
export const getChannelBillingModeInfo = (params) =>
  http.get("/v1/Channel/GetChannelBillingModeInfo", params);

// 获取渠道计费模式表格
export const getChannelBillingModeTable = (params) =>
  http.get("/v1/Channel/GetChannelBillingModeTable", params);
